<template>
  <div class="nav_wrapper">
    <div class="nav_wrapper_box">
      <div class="left_box">
        <a
          class="nav_logo"
          href="/"
        >
          <img
            v-if="componentInfo?.websiteLogo"
            class="nav_logo_img"
            :src="componentInfo.websiteLogo"
            object-fit="cover"
          >
        </a>
        <ul
          v-show="componentInfo.contentList && componentInfo.contentList.length"
          class="nav_list_wrapper"
        >
          <template v-for="(navItem, index) in componentInfo.contentList">
            <a
              v-if="(index < showNum || componentInfo.contentList.length === showNum) && navItem.status"
              :key="navItem.id"
              :href="handleNavigate(navItem)"
              :target-type="navItem.targetType"
              :target-open-new-page="navItem.targetOpenNewPage"
              class="nav_link"
            >
              <li
                class="nav_item"
                :class="{ active: index === componentInfo.activeIndex }"
              >
                {{ navItem.navName }}
              </li>
            </a>
          </template>
          <client-only>
            <el-popover
              v-if="componentInfo.contentList?.length > showNum"
              placement="bottom"
              trigger="click"
              popper-class="nav_popover"
              :width="164"
              @show="showheadMoreBg()"
              @hide="hideheadMoreBg()"
            >
              <template #reference>
                <li
                  class="nav_item nav_more_item"
                  :class="{ active: activeItem >= showNum }"
                >
                  <el-icon
                    size="26"
                    class="nav_more_item_icon"
                  >
                    <MoreFilled />
                  </el-icon>
                </li>
              </template>
              <ul class="nav_list_wrapper more_items">
                <template v-for="(navItem, index) in componentInfo.contentList">
                  <a
                    v-if="index >= showNum"
                    :key="navItem.id"
                    :href="handleNavigate(navItem)"
                    :target-type="navItem.targetType"
                    :target-open-new-page="navItem.targetOpenNewPage"
                  >
                    <li
                      :key="navItem.id"
                      class="nav_color_item"
                      :class="{ active_color: index === componentInfo.activeIndex }"
                    >
                      {{ navItem.navName }}
                    </li>
                  </a>
                </template>
              </ul>
            </el-popover>
          </client-only>
        </ul>
      </div>
      <div class="right_box">
        <index-header-box :component-info="componentInfo" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { MoreFilled } from '@element-plus/icons-vue'
import { handleNavigate } from '@/utils/utils'
import { getHearder } from '@/api/layouts'
import { useNavStore } from '@/stores/nav'
import { useCartStore } from '@/stores/cart'
import { useUserStore } from '../../../stores/user'

const cartStore = useCartStore()
const store = useNavStore()
const { getToken } = useUserStore()

const router = useRouter()
const route = useRoute()
const showNum = ref(7)
const activeItem = ref(-1)
const isMore = ref(false)
const { data: componentInfo } = await useAsyncData('headers', async () => {
  try {
    let activeIndex = 0
    const data = await getHearder()
    if (data && data.contentList && data.contentList.length) {
      let is = false
      data.contentList.forEach((navItem, i) => {
        navItem.path = handleNavigate(navItem)
        if (navItem.path !== '/') {
          if (route.fullPath.indexOf(navItem.path) != -1) {
            activeIndex = i
            is = true
          }
        } else {
          if (route.path === navItem.path) {
            activeIndex = i
            is = true
          }
        }
      })
      if (!is) {
        activeIndex = -1
      }
    }
    data.activeIndex = activeIndex
    return data
  } catch (e) {
    console.error(e)
  }
})

// store.setNav(componentInfo.value)

if (getToken()) {
  cartStore.updateCartNum()
}

router.beforeResolve((to) => {
  if (componentInfo && componentInfo.value) {
    const contentList = componentInfo.value.contentList
    if (contentList && contentList.length) {
      let is = false
      contentList.forEach((e, i) => {
        if (e.path !== '/') {
          if (to.fullPath.indexOf(e.path) != -1) {
            activeItem.value = i
            is = true
          }
        } else {
          if (to.path === e.path) {
            activeItem.value = i
            is = true
          }
        }
      })
      if (!is) {
        activeItem.value = -1
      }
    }
  }
})
const showheadMoreBg = () => {
  isMore.value = true
}
const hideheadMoreBg = () => {
  isMore.value = false
}
</script>

<style lang="scss">
.nav_wrapper {
  text-align: center;
  height: 66px;
}
.nav_wrapper_box {
  min-width: 1200px;
  display: inline-block;
}

.left_box {
  padding-top: 13px;
  float: left;
}
.right_box {
  float: right;
  display: inline-block;
}
.nav_popover{
  padding: 0 !important;
  min-width: none !important;
}
.nav_logo {
  float: left;
  position: relative;
  top: -1px;
  width: 170px;
  height: 36px;

  .nav_logo_img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
  .nav_list_wrapper {
    float: left;
    white-space: nowrap;
    padding-left: 20px;
    color: #333333;
    &>a, &>div{
      float: left;
    }
    .nav_item {
      display: inline-block;
      margin-right: 42px;
      color: #333;
      font-size: 18px;
      line-height: 40px;
      white-space: nowrap;
      .nav_item_more {
        min-width: 20px;
      }
      &:hover{
        cursor: pointer;
        position: relative;
        color: var(--el-color-primary);
      }
    }
    .nav_more_item {
      margin-right: 6px;
      vertical-align: bottom;

      .nav_more_item_icon {
        height: 40px;
        padding: 5px 0;
        display: block;
        line-height: 20px;
      }
    }
    .nav_color_item{
      height: 50px;
      padding: 15px 26px;
      font-size: 16px;
      color: #333333;
      text-align: center;
      white-space: nowrap;
      &:hover{
        cursor: pointer;
        position: relative;
        color: var(--el-color-primary);
      }
    }
    .nav_item:hover::after {
      content: " ";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 20px;
      height: 3px;
      border-radius: 1.5px;
      background-color: var(--el-color-primary);
    }
    .active {
      position: relative;
      color: var(--el-color-primary);
    }
    .active::after {
      content: " ";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 20px;
      height: 3px;
      border-radius: 1.5px;
      background-color: var(--el-color-primary);
    }
  }
  .more_items {
    flex-direction: column;
    padding-left: 0;
    .nav_item {
      justify-content: center;
      margin: 11px 0px;
      margin-right: 0;
    }
    .nav_item:first-child {
      margin-top: 3px;
    }
    .nav_item:last-child {
      margin-bottom: 3px;
    }
    .active_color {
      color: #2256f6;
    }
  }
  .handle_bar {
    display: flex;
    align-items: center;
    font-size: 16px;
    height: 36px;
  }
</style>
